html,body{
    margin:0;
    padding:0;
    font-size:13px;
}

.space-between{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.indexs-wrap{
    border-radius:5px;
    display:inline-block;
    padding:2px;
    background-color:white;
    width:100%;
    box-shadow:0px 0px 5px rgba(0,0,0,.15);
}


/*关于组件库里的部分重写*/
.sh-card{
    margin:8px!important;
    box-shadow:0px 0px 5px rgba(0,0,0,.15);
}

.sh-modal-mask{
    backdrop-filter:blur(5px) saturate(120%);
    /*-webkit-backdrop-filter:blur(8px) saturate(120%);*/
}