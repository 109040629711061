.logo-area{
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-bottom:24px;
}
.logo-svg{
    fill:#5c434a;
}
.logo-text{
    color:#5c434a;
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.logo-text > h1{
    font-size:1.845rem;
}
.logo-text > p{
    font-size:1.23rem;
}
.logo-text > h1,.logo-text > p{
    margin:0;
    line-height:1.5;
    user-select:none;
    white-space:nowrap;
}
