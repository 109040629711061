.register-button{
    width:100%;
}
.register-button-loading{
    opacity:0.9;
}
.extra-area{
    display:flex;
}
.extra-button{
    flex:1;
    margin:0 3px;
}
.flex-between{
    display:flex;
    justify-content:space-between;
}