.steps{
    margin-bottom:24px;
}
.step{
    //文字过长变省略号
    //background-color:lightcoral;
    //overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.captcha-button{
    width:100%;
}
.captcha-button-loading{
    opacity:0.9;
}
.flex-between{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}
.extra-area{
    display:flex;
}
.extra-button{
    flex:1;
    margin:0 3px;
}
.as-input-span-package{
    //background-color: lightcoral;
    .as-input-span{
        position:relative;
        padding-left:8px;
        opacity:.9;
    }
    .as-input-span::before{
        content:':';
        display:inline;
        position:absolute;
        left:0;
    }
}