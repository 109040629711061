.app{
    background-color:transparent;
    min-height:100%;
    height:100%;
}
.nav{
    /*
    font-size:36px;
    color:red;
    */
    display:block;
}
.show-area{
    /*
    color:teal;
    */
    display:block;
}
/*
.spin-container{
    width:100%;
    height:100%;
    background-color:red;
}
*/

.inner-loading{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    background-color:#f9efe9;
}

.inner-loading-text{
    margin:12px;
    color:darkgray;
    font-size:0.9rem;
    line-height:1.8;
}