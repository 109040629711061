.login-remember-area {
    display: flex;
    justify-content: space-between;
}
.login-remember-area > div {
    height: auto;
}
.login-button {
    width: 100%;
}
.login-button-loading {
    /*background-color:lightgray!important;*/
    opacity: 0.9;
}

.extra-area {
    display: flex;
}
.extra-button {
    flex: 1;
    margin: 0 3px;
}
.forchild-noselect span {
    user-select: none;
    align-self: baseline;
}
.forchild-noselect > label > span {
    top: 0.29em !important;
}
.forchild-noselect > * {
    align-items: baseline;
}

.qrcode-package {
    width: 100%;
    display: flex;
    justify-content: center;
}
