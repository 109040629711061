/*定义NoLogin组件的动画*/
.nlp-enter,.nlp-appear{
    filter:grayscale(20%);
    opacity:0.5;
}
.nlp-enter-active,.nlp-appear-active{
    transition:1s;
}
.nlp-enter-done,.nlp-enter-active,.nlp-appear-active{
    filter:none;
    opacity:1;
}

.nlp-exit{
    filter:none;
    opacity:1;
}
.nlp-exit-active{
    transition:1s;
}
.nlp-exit-done,.nlp-exit-active{
    filter:grayscale(20%);
    opacity:0.5;
}