.container{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    height:100%;
    width:100%;
    min-height:100%;
    min-width:100%;
}
.icon{
    margin:0.5rem;
    color:#ed1e79;
}
.icon svg{
    width:24px;
    height:24px;
    stroke:#ed1e79!important;
    fill:#ed1e79!important;
    color:#ed1e79!important;
}
.msg{
    margin:0.9rem 0;
    color:darkgray;
}
.msg > span{
    line-height:1.5;
}