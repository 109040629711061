@font-face{
    font-family: 'xiane';
    font-style: normal;
    font-weight: 400;
    src:url('../../assets/fonts/xiane.woff2') format('woff2'),
        url('../../assets/fonts/xiane.woff') format('woff'),
        url('../../assets/fonts/xiane.ttf') format('truetype'),
        url('../../assets/fonts/xiane.svg') format('svg'),
        url('../../assets/fonts/xiane.eot') format('embedded-opentype');
    font-display: swap;
}
@font-face{
    font-family: 'sheaneh';
    font-style: normal;
    font-weight: 400;
    src:url('../../assets/fonts/sheaneh.woff2') format('woff2'),
        url('../../assets/fonts/sheaneh.woff') format('woff'),
        url('../../assets/fonts/sheaneh.ttf') format('truetype'),
        url('../../assets/fonts/sheaneh.svg') format('svg'),
        url('../../assets/fonts/sheaneh.eot') format('embedded-opentype');
    font-display: swap;
}
html{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /*
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
    */
}
html,body{
    margin:0;
    padding:0;
    font-family:'xiane';
    color:#30353D;
}
input,textarea{
    outline:medium;
    resize:none;
}

.ant-form-item-control-input-content{
    display:flex;
    justify-content: space-between;
}

html,body,#root{
    height:100%;
}
*::-webkit-scrollbar{
    width:7px;
    height:7px;
}
*::-webkit-scrollbar-track-piece{
    background-color:#e0dfe0;
}
*::-webkit-scrollbar-track-piece:hover{
    background-color:#E2D4D2;
}
*::-webkit-scrollbar-thumb{
    height:30px;
    border-radius:3px;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    /*background-color:#ed1e78c9;*/
    background-color:#e985b0b9;
    border:1px solid #ecc6d1;
}
*::-webkit-scrollbar-thumb:hover{
    /*background-color:#ed1e79;ffedf2*/
    background-color:#e985b0;
}
*::-webkit-scrollbar-thumb:hover ~html::-webkit-scrollbar-track-piece{
    background-color:#E2D4D2;
}